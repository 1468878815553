<template>
  <setup-table
    :title="`Trip Event`"
    :items="items"
    :headers="headers"
    :loading="loading"
    :clearItem="!showForm || !editedItem.id"
    @createItem="createItem()"
    @editItem="editItem"
    @deleteItems="deleteItems"
  >
    <template #itemForm v-if="showForm">
      <trip-event-form
        ref="tripEventForm"
        :value="editedItem"
        :tripTypes="allTripTypes"
        @submit="saveItem"
        @closeForm="closeForm"
      ></trip-event-form>
    </template>

    <template #[`item.tripType`]="{ item }">
      <p class="mb-0">{{ allTripTypes.find((e) => e.id == item.tripTypeId).name }}</p>
    </template>
    <template #[`item.mileageRate`]="{ item }">
      <p class="mb-0" v-if="!hasAllowTripEventRateEnabled(item.tripTypeId)">
        <v-icon small>mdi-close</v-icon>
      </p>
      <p class="mb-0" v-else>{{ item.mileageRate ? toMoney(item.mileageRate, 4) : '-' }}</p>
    </template>
    <template #[`item.driverRate`]="{ item }">
      <p class="mb-0" v-if="!hasAllowTripEventRateEnabled(item.tripTypeId)">
        <v-icon small>mdi-close</v-icon>
      </p>
      <p class="mb-0" v-else>{{ item.overrideDriver ? toMoney(item.driverRate) : 'N/A' }}</p>
    </template>
    <template #[`item.overrideDriver`]="{ item }">
      <p class="mb-0" v-if="!hasAllowTripEventRateEnabled(item.tripTypeId)">
        <v-icon small>mdi-close</v-icon>
      </p>
      <v-icon small v-else>{{ item.overrideDriver ? 'mdi-check' : 'mdi-close' }}</v-icon>
    </template>
    <template #[`item.defaultEvent`]="{ item }">
      <v-icon small v-if="item.defaultEvent">mdi-check</v-icon>
    </template>
  </setup-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_TRIP_EVENTS, SAVE_TRIP_EVENT, DELETE_TRIP_EVENTS } from '@/store/modules/TripEvent/actions';
import { GET_TRIP_TYPES } from '@/store/modules/TripType/actions';
import SetupTable from './SetupTable.vue';
import TripEventForm from './TripEventForm.vue';
import { toMoney } from '@/util';

export default {
  name: 'TripEventSetupTable',
  inject: ['eventHub'],
  components: { SetupTable, TripEventForm },
  data() {
    return {
      toMoney,
      showForm: false,
      items: [],
      editedItem: {},
      loading: true,
      headers: [
        { text: 'Trip Event', value: 'name' },
        { text: 'Trip Type', value: 'tripType' },
        { text: 'Mileage Rate', value: 'mileageRate' },
        { text: 'Ovrd Drv Rate', value: 'overrideDriver' },
        { text: 'Driver Rate', value: 'driverRate' },
        { text: 'Default Event', value: 'defaultEvent' },
      ],
    };
  },
  computed: {
    ...mapGetters('tripEvent', ['tripEvents']),
    ...mapGetters('tripType', ['allTripTypes', 'tripTypesById']),
  },
  mounted() {
    this.fetchItems();
  },
  methods: {
    ...mapActions('tripEvent', [GET_TRIP_EVENTS, SAVE_TRIP_EVENT, DELETE_TRIP_EVENTS]),
    ...mapActions('tripType', [GET_TRIP_TYPES]),
    async fetchItems() {
      await this.getTripEvents({ forTable: true });
      await this.getTripTypes();
      this.items = this.tripEvents;
      this.loading = false;
    },
    createItem() {
      this.showForm = true;
      this.editedItem = { id: 0, name: '' };
    },
    editItem(item) {
      this.editedItem = { ...item };
      this.showForm = true;
    },
    async saveItem(item) {
      try {
        const r = await this.saveTripEvent(item);
        if (r && r.id) {
          this.$myalert.success('Trip Event saved');
          await this.fetchItems();
          this.editedItem = { ...item, id: r.id };
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    async deleteItems(items) {
      const yes = await this.$myconfirm(
        `Are you sure you want to delete ${
          items.length > 1 ? 'these Trip Events' : 'this Trip Event'
        }? This operation cannot be undone.`
      );
      if (yes) {
        const r = await this.deleteTripEvents(items.map((e) => e.id));
        if (r.done) {
          this.$myalert.success(`${items.length > 1 ? 'Trip Events' : 'Trip Event'} deleted`);
          this.showForm = false;
          this.fetchItems();
        }
      }
    },
    closeForm() {
      this.showForm = false;
    },
    hasAllowTripEventRateEnabled(tripTypeId) {
      return this.tripTypesById[tripTypeId].allowEventRates ?? 0;
    },
  },
};
</script>

<style lang="scss">
.table-action {
  margin-left: 10px;
}
</style>
